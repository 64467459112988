import moment from "moment/moment";
import { useEffect, useState } from "react";
import Event from "../Event/Event";
import classes from "./MonthView.module.css";
export default function MonthView(props) {
  const [weeks, setWeeks] = useState([]);
  const [calendarWeeks, setCalendarWeeks] = useState([]);

  const clickDay = (e, day) => {
    if (e.target.classList.contains(classes.Day)) {
      props.clickDay(day);
    }
  };

  useEffect(() => {
    //SO: 0, MO: 1
    const date = moment(`${props.currentYear}-${props.currentMonth}-01`, "YYYY-MM-DD").startOf("month");
    const firstDay = date.day();
    const daysOfMonth = date.daysInMonth();
    const lastMonth = date.clone().subtract(1, "days");
    const nextMonth = date.clone().add(1, "month");

    const resultDaysDates = [];

    const tmp = firstDay >= 1 ? lastMonth.daysInMonth() - (firstDay - 2) : lastMonth.daysInMonth() - 5;

    /**
     * Add the last days of the previous month
     */
    for (let i = tmp; i <= lastMonth.daysInMonth(); i++) {
      const d = moment(lastMonth.format("YYYY-MM") + "-" + i, "YYYY-MM-DD");
      resultDaysDates.push({ date: d, additional_class: classes.Previous });
    }

    /**
     * Add the days of the current month
     */
    for (let i = 1; i <= daysOfMonth; i++) {
      const d = moment(date.format("YYYY-MM") + "-" + i, "YYYY-MM-DD");
      resultDaysDates.push({ date: d, additional_class: null });
    }

    /**
     * Add the first days of the next month
     */
    let i = 1;
    while (resultDaysDates.length % 7 !== 0) {
      const d = moment(nextMonth.format("YYYY-MM") + "-" + i, "YYYY-MM-DD");
      resultDaysDates.push({ date: d, additional_class: classes.Next });
      i++;
    }

    const weeks = [];
    const calendarWeeksTmp = [];

    while (resultDaysDates.length > 0) {
      const curWeek = [];
      for (let i = 0; i < 7; i++) {
        //Create a day in the calendar and push events into it
        const this_day = resultDaysDates[0];
        const id = this_day.date.format("YYYY-MM-DD");
        const evs = getEventsOnDay(id);

        const labelClasses = [classes.DayLabel];
        if (moment(id).isSame(moment(), "D")) {
          labelClasses.push(classes.CurrentDayLabel);
        }

        curWeek.push(
          <div
            onClick={(e) => clickDay(e, id)}
            key={id}
            id={id}
            className={classes.Day + " " + this_day.additional_class}
          >
            <span className={labelClasses.join(" ")}>{this_day.date.format("DD")}</span>
            {evs.map((e) => (
              <Event
                showNicknames={props.showNicknames}
                clickEvent={(id) => props.clickEvent(id)}
                key={e.id}
                event={e}
              />
            ))}
          </div>
        );
        resultDaysDates.splice(0, 1);

        if (i === 0) {
          calendarWeeksTmp.push(this_day.date.isoWeek());
        }
      }
      weeks.push(curWeek);
    }

    setCalendarWeeks(calendarWeeksTmp);
    setWeeks(weeks);
  }, [props.currentMonth, props.currentYear, props.events]);

  const getEventsOnDay = (day) => {
    const m_day = moment(day, "YYYY-MM-DD");

    const res = [];

    props.events.forEach((e) => {
      const start = moment(e.attributes.start_date, "YYYY-MM-DD");
      const end = moment(e.attributes.end_date, "YYYY-MM-DD");

      if (m_day.isBetween(start, end, "day", "[]")) {
        res.unshift(e);
      }
    });

    return res;
  };

  const cls = [classes.MonthView];
  if (props.swipeRight) {
    cls.push(classes.SwipeRight);
  } else if (props.swipeLeft) {
    cls.push(classes.SwipeLeft);
  }

  const loaderClass = [classes.Loader];
  if (props.loading) {
    loaderClass.push(classes.Loading);
  }

  if (props.loadingLong) {
    loaderClass.push(classes.LoadingLong);
  }

  return (
    <div className={classes.Container}>
      <section className={classes.CalendarWeeks}>
        {calendarWeeks.map((c) => (
          <div key={c}>{c}</div>
        ))}
      </section>
      <div className={loaderClass.join(" ")}>
        <div className={classes.Spinner}></div>
        <div className={classes.BackendHint}>
          Das Backend befand sich im Standby und wird nun hochgefahren, bitte warte kurz oder schau in einer Minute
          nochmal vorbei.
        </div>
      </div>
      <section
        onTouchStart={(e) => props.onTouchStart(e)}
        onTouchMove={(e) => props.onTouchMove(e)}
        onTouchEnd={(e) => props.onTouchEnd()}
        className={cls.join(" ")}
      >
        {weeks.map((w, i) => (
          <section key={i} className={classes.Week}>
            {w}
          </section>
        ))}
      </section>
    </div>
  );
}
