import { Button } from "@mui/material";

export default function SessionError() {
  const logout = () => {
    window.localStorage.removeItem("wallis-token");
    window.location.href = "/login";
  };

  return (
    <section>
      <h2>Session abgelaufen</h2>
      <p>
        Ihre Session ist nicht länger gültig oder das Backend ist gerade nicht erreichbar sodass keine Termine geladen
        werden konnten.
      </p>
      <p>
        Bitte melden Sie sich ab und wieder an. Sollte das Problem weiterhin bestehen, kontaktieren Sie bitte den
        Webseitenadministrator.
      </p>
      <Button variant="outlined" color="warning" onClick={logout}>
        Logout
      </Button>
    </section>
  );
}
