import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import moment from "moment";
import { useEffect, useState } from "react";
import axios from "axios";
import classes from "./BookingForm.module.css";

export default function BookingForm(props) {
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment().add(1, "day"));
  const [visitors, setVisitors] = useState(1);
  const [definite, setDefinite] = useState(true);
  const [comment, setComment] = useState("");
  const [ownBooking, setOwnBooking] = useState(false);
  const [author, setAuthor] = useState("");
  const [visitorError, setVisitorError] = useState(false);
  const [loading, setLoading] = useState(false);

  const axiosOptions = {
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem("wallis-token")}`,
    },
  };

  const deleteBooking = () => {
    if (props.editing !== false) {
      if (window.confirm("Diese Buchung wirklich löschen?")) {
        axios
          .delete(
            `${process.env.REACT_APP_API}/api/bookings/${props.editing}`,
            axiosOptions
          )
          .finally(() => {
            props.submitBookingForm();
          });
      }
    }
  };

  const updateVisitors = (amount) => {
    setVisitors(amount);
    if (amount <= 0 || isNaN(amount)) {
      setVisitorError(true);
    } else {
      setVisitorError(false);
    }
  };

  const submitForm = (e) => {
    e.preventDefault();

    if (visitorError) {
      return false;
    }

    const data = {
      start_date: moment(startDate).format("YYYY-MM-DD"),
      end_date: moment(endDate).format("YYYY-MM-DD"),
      visitors: visitors,
      definite: definite,
      comment: comment,
    };

    if (props.editing !== false) {
      axios
        .put(
          `${process.env.REACT_APP_API}/api/bookings/${props.editing}`,
          {
            data: data,
          },
          axiosOptions
        )
        .finally(() => props.submitBookingForm());
    } else {
      axios
        .post(
          `${process.env.REACT_APP_API}/api/bookings`,
          {
            data: data,
          },
          axiosOptions
        )
        .finally(() => props.submitBookingForm());
    }
  };

  const loadBookingData = async (id) => {
    setLoading(true);

    const data = (
      await axios.get(
        `${process.env.REACT_APP_API}/api/bookings/${id}?populate[0]=author`,
        axiosOptions
      )
    ).data.data;
    const user = (
      await axios.get(`${process.env.REACT_APP_API}/api/users/me`, axiosOptions)
    ).data;
    setStartDate(moment(data.attributes.start_date, "YYYY-MM-DD"));
    setEndDate(moment(data.attributes.end_date, "YYYY-MM-DD"));
    setVisitors(data.attributes.visitors);
    setDefinite(data.attributes.definite);
    setComment(data.attributes.comment);

    setLoading(false);

    if (props.showNicknames === "true") {
      setAuthor(data.attributes.author.data.attributes.nickname);
    } else {
      setAuthor(data.attributes.author.data.attributes.username);
    }
    if (user.id === data.attributes.author.data.id) {
      setOwnBooking(true);
    } else {
      setOwnBooking(false);
    }
  };

  useEffect(() => {
    if (props.editing !== false) {
      loadBookingData(props.editing);
    }

    if (props.createOnDay !== false) {
      setStartDate(moment(props.createOnDay, "YYYY-MM-DD"));
      setEndDate(moment(props.createOnDay, "YYYY-MM-DD").add(7, "days"));
    }
  }, [props.editing, props.createOnDay]);

  let title = "Neue Buchung erfassen";
  if (props.editing !== false && ownBooking) {
    title = "Buchung bearbeiten";
  } else if (props.editing !== false && !ownBooking) {
    title = "Buchung einsehen";
  }

  const formCls = [classes.Form];
  if (loading) {
    formCls.push(classes.Loading);
  }

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <FormGroup>
        <h2 className={classes.Title}>{title}</h2>
        {author ? <h3 className={classes.Author}>von {author}</h3> : null}
        <form className={formCls.join(" ")} onSubmit={(e) => submitForm(e)}>
          <div className={classes.FormRow}>
            <MobileDatePicker
              disabled={!ownBooking && props.editing !== false}
              label="Startdatum*"
              inputFormat="DD.MM.YYYY"
              value={startDate}
              onChange={(val) => setStartDate(val)}
              renderInput={(params) => <TextField {...params} />}
            />
          </div>
          <div className={classes.FormRow}>
            <MobileDatePicker
              disabled={!ownBooking && props.editing !== false}
              label="Enddatum*"
              inputFormat="DD.MM.YYYY"
              value={endDate}
              onChange={(val) => setEndDate(val)}
              renderInput={(params) => <TextField {...params} />}
            />
          </div>

          <div className={classes.FormRowFlex}>
            <TextField
              disabled={!ownBooking && props.editing !== false}
              onChange={(e) => updateVisitors(e.target.value)}
              type="number"
              name="visitors"
              error={visitorError}
              value={visitors}
              label="Besucher*"
            />

            <FormControlLabel
              control={
                <Checkbox
                  disabled={!ownBooking && props.editing !== false}
                  onChange={() => setDefinite(!definite)}
                  checked={definite}
                />
              }
              label="Definitiv"
            />
          </div>
          <div className={classes.FormRow}></div>

          <div className={classes.FormRow}>
            <TextField
              disabled={!ownBooking && props.editing !== false}
              label="Kommentar"
              multiline
              value={comment || ""}
              onChange={(e) => setComment(e.target.value)}
              rows={4}
            />
          </div>

          {ownBooking || props.editing === false ? (
            <section className={classes.Buttons}>
              {props.editing ? (
                <Button
                  className={classes.Button}
                  variant="outlined"
                  color="error"
                  onClick={deleteBooking}
                >
                  Löschen
                </Button>
              ) : null}
              <Button
                className={classes.Button}
                type="submit"
                variant="contained"
              >
                Speichern
              </Button>
            </section>
          ) : null}
        </form>
      </FormGroup>
    </LocalizationProvider>
  );
}
