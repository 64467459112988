import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import classes from "./ResetPassword.module.css";
import bg from "../img/bg2.webp";
import { Button, TextField } from "@mui/material";

export default function ResetPassword(props) {
  const nav = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [loading, setLoading] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  useEffect(() => {}, [nav]);

  const submit = (e) => {
    setLoading(true);
    e.preventDefault();
    if (passwordError) return false;

    const code = searchParams.get("code");
    axios
      .post(`${process.env.REACT_APP_API}/api/auth/reset-password`, {
        code: code,
        password: password1,
        passwordConfirmation: password2,
      })
      .then((res) => {
        alert(
          "Dein Passwort wurde erfolgreich zurückgesetzt. Du wirst nun zum Login weitergeleitet."
        );
        nav("/login");
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (password1 !== password2 && password1.length > 0) {
      setPasswordError(true);
    } else {
      setPasswordError(false);
    }
  }, [password1, password2]);

  return (
    <section>
      <div
        style={{ backgroundImage: `url(${bg})` }}
        className={classes.Background}
      ></div>
      <form className={classes.Login} onSubmit={(e) => submit(e)}>
        <h1 className={classes.Title}>Passwort zurücksetzen</h1>
        <TextField
          onChange={(e) => setPassword1(e.target.value)}
          value={password1}
          label="Neues Passwort"
          type="password"
          margin="normal"
          error={passwordError}
          className={classes.Wide}
        />
        <br />
        <TextField
          onChange={(e) => setPassword2(e.target.value)}
          value={password2}
          label="Passwort wiederholen"
          type="password"
          margin="normal"
          error={passwordError}
          className={classes.Wide}
        />
        {loading ? (
          <Button
            disabled={true}
            style={{ marginTop: "10px" }}
            className={classes.Wide + " " + classes.Loading}
            variant="contained"
          >
            &nbsp;
          </Button>
        ) : (
          <Button
            style={{ marginTop: "10px" }}
            className={classes.Wide}
            type="submit"
            variant="contained"
          >
            Passwort zurücksetzen
          </Button>
        )}
      </form>
    </section>
  );
}
