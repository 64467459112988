import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import classes from "./Login.module.css";
import bg from "../img/bg2.webp";
import { Button, TextField } from "@mui/material";

const Login = (props) => {
  const nav = useNavigate();

  const [identifier, setIdentifier] = useState("");
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingLong, setLoadingLong] = useState(false);
  const [forgotPasswordView, setForgotPasswordView] = useState(false);

  useEffect(() => {
    const jwt = window.localStorage.getItem("wallis-token");
    if (jwt) {
      axios
        .get(`${process.env.REACT_APP_API}/api/users/me`, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        })
        .then((res) => {
          nav("/");
        })
        .catch((err) => {
          window.localStorage.removeItem("wallis-token");
        });
    }
  }, [nav]);

  const submit = (e) => {
    e.preventDefault();

    const longLoadTimeout = setTimeout(() => {
      setLoadingLong(true);
    }, 4000);

    if (forgotPasswordView) {
      forgotPassword(e, longLoadTimeout);
    } else {
      login(e, longLoadTimeout);
    }
  };

  const forgotPassword = (e, timeout) => {
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_API}/api/auth/forgot-password`, {
        email: identifier,
      })
      .then((res) => {
        alert(
          "Es wurde eine Email mit dem Link zum Zurücksetzen des Passwortes an die angegebene Adresse versendet."
        );
      })
      .catch((err) => {
        alert(
          "Ein unerwarteter Fehler ist aufgetreten. Bitte versuche es ernuet oder wende dich an den Administrator."
        );
      })
      .finally(() => {
        setLoading(false);
        setLoadingLong(false);
        clearTimeout(timeout);
      });
  };

  const login = (e, timeout) => {
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_API}/api/auth/local`, {
        identifier: identifier,
        password: password,
      })
      .then((res) => {
        window.localStorage.setItem("show-nicks", res.data.user.show_nicknames);
        window.localStorage.setItem("wallis-token", res.data.jwt);
        nav("/");
      })
      .catch((err) => {
        setLoginError(err.response);
        setTimeout(() => {
          setLoginError(false);
        }, 2000);
      })
      .finally(() => {
        setLoading(false);
        setLoadingLong(false);
        clearTimeout(timeout);
      });
  };

  const hintCls = [classes.Hint];
  if (loadingLong) {
    hintCls.push(classes.ShowHint);
  }

  return (
    <section>
      <div
        style={{ backgroundImage: `url(${bg})` }}
        className={classes.Background}
      ></div>
      <form className={classes.Login} onSubmit={(e) => submit(e)}>
        <h1 className={classes.Title}>Chalet Sangj - Mühlebach</h1>
        {loginError !== false ? (
          <p className={classes.Error}>Login fehlgeschlagen!</p>
        ) : null}
        <TextField
          onChange={(e) => setIdentifier(e.target.value)}
          value={identifier}
          label="Email"
          margin="normal"
          className={classes.Wide}
        />
        <br />
        {forgotPasswordView ? null : (
          <TextField
            margin="normal"
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            value={password}
            label="Passwort"
            className={classes.Wide}
          />
        )}
        {loading ? (
          <Button
            disabled={true}
            style={{ marginTop: "10px" }}
            className={classes.Wide + " " + classes.Loading}
            variant="contained"
          >
            &nbsp;
          </Button>
        ) : (
          <Button
            style={{ marginTop: "10px" }}
            className={classes.Wide}
            type="submit"
            variant="contained"
          >
            {forgotPasswordView ? "Passwort zurücksetzen" : "Login"}
          </Button>
        )}
        <Button
          style={{ marginTop: "10px" }}
          onClick={() => setForgotPasswordView(!forgotPasswordView)}
        >
          {forgotPasswordView ? "Zurück" : "Passwort vergessen"}
        </Button>
      </form>
      <div className={hintCls.join(" ")}>
        <p>
          <b>Hinweis:</b> Das Backend läuft auf einem Gratis-Server, welcher in
          den Standby geht, wenn er mehr als 15 Minuten nicht verwendet wurde.
          Wenn der Login sehr lange dauert, wird der Server gerade aus dem
          Standby hochgefahren, was ungefähr eine Minute dauern kann. Du kannst
          entweder warten oder es in ein paar Minuten erneut versuchen.
        </p>
      </div>
    </section>
  );
};

export default Login;
