import classes from "./Controls.module.css";

export default function Controls(props) {
  const logout = () => {
    window.localStorage.removeItem("wallis-token");
    window.location.href = "/login";
  };

  const monthLabels = [
    "Januar",
    "Februar",
    "März",
    "April",
    "Mai",
    "Juni",
    "Juli",
    "August",
    "September",
    "Oktober",
    "November",
    "Dezember",
  ];

  return (
    <section className={classes.Controls}>
      <section className={classes.Logo}>
        <span>🏂</span><p>Haus im Sangj</p>
      </section>
      <section className={classes.SwitchControls}>
        <p className={classes.Today} onClick={props.gotoToday}>
          Heute
        </p>
        <p
          className={classes.Arrow + " " + classes.Prev}
          onClick={props.prevMonth}
        ></p>
        <p
          className={classes.Arrow + " " + classes.Next}
          onClick={props.nextMonth}
        ></p>
      </section>
      <p className={classes.MonthLabel}>
        {monthLabels[props.currentDate.month()]} {props.currentDate.year()}
      </p>
      <p className={classes.Logout} onClick={props.openProfileSettings}>Profil 🎭</p>
    </section>
  );
}
