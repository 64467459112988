import "./App.css";
import { useEffect, useState } from "react";
import Calendar from "./Calendar/Calendar";

const App = (props) => {
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    const token = window.localStorage.getItem("wallis-token");
    if (token === null) {
      window.location.href = "/login";
    } else {
      setLoggedIn(true);
    }
  }, []);

  return <div className="App">{loggedIn ? <Calendar /> : null}</div>;
};

export default App;
