import { Button, Checkbox, FormControlLabel, FormGroup, FormLabel, TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useEffect, useState } from "react";
import axios from "axios";
import ColorPicker from "../ColorPicker/ColorPicker";
import classes from "./ProfileForm.module.css";

export default function ProfileForm(props) {
  const [user, setUser] = useState({
    username: "",
    nickname: "",
    show_nicknames: false,
    color: "",
  });

  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");

  const axiosOptions = {
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem("wallis-token")}`,
    },
  };

  const submitForm = (e) => {
    e.preventDefault();

    const updateData = {
      username: user.username,
      nickname: user.nickname,
      show_nicknames: user.show_nicknames,
      color: user.color,
    };

    if (password.length > 0 && password2 === password) {
      updateData.password = password;
    }

    axios
      .put(`${process.env.REACT_APP_API}/api/users/${user.id}`, updateData, axiosOptions)
      .then(() => {
        window.localStorage.setItem("show-nicks", updateData.show_nicknames);
      })
      .finally(() => {
        props.submitProfileForm();
      });
  };

  const logout = () => {
    if (window.confirm("Wirklich abmelden?")) {
      window.localStorage.removeItem("wallis-token");
      window.location.href = "/login";
    }
  };

  const loadProfileData = async () => {
    const user = (await axios.get(`${process.env.REACT_APP_API}/api/users/me`, axiosOptions)).data;
    setUser(user);
  };

  const updateField = (val, field) => {
    const tmp = { ...user };
    tmp[field] = val;
    setUser(tmp);
  };

  useEffect(() => {
    loadProfileData();
  }, []);

  const colors = [
    "#FF6352",
    "#FF7E2E",
    "#FF9D1F",
    "#FFBC11",
    "#FFDB02",
    "#FFF673",

    "#316764",
    "#497F76",
    "#609687",
    "#78AE99",
    "#8FC5AA",
    "#A7DDBC",

    "#44648E",
    "#5B80AF",
    "#6893C0",
    "#71A6D1",
    "#6AB6D9",
    "#78CFDE",

    "#2C2796",
    "#3F369E",
    "#5245A6",
    "#6554AE",
    "#7863B6",
    "#8B72BE",

    "#765F53",
    "#A88877",
    "#BA9B86",
    "#D3B39A",
    "#E2C3A3",
    "#F5D5BC",
  ];

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <FormGroup>
        <h2 className={classes.Title}>Profil bearbeiten</h2>
        <form className={classes.Form} onSubmit={(e) => submitForm(e)}>
          <div className={classes.FormRow}></div>
          <div className={classes.FormRow}>
            <TextField
              onChange={(e) => updateField(e.target.value, "username")}
              type="text"
              name="username"
              value={user?.username}
              label="Benutzername*"
            />
          </div>
          <div className={classes.FormRow}>
            <TextField
              onChange={(e) => updateField(e.target.value, "nickname")}
              type="text"
              name="nickname"
              value={user?.nickname}
              label="Spitzname*"
            />
          </div>
          <div className={classes.FormRow}>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={() => updateField(!user.show_nicknames, "show_nicknames")}
                  checked={user.show_nicknames}
                />
              }
              label="Spitznamen anzeigen"
            />
          </div>
          <div className={classes.FormRow}>
            <FormLabel>Farbe</FormLabel>
            <ColorPicker colors={colors} value={user.color} onChange={(val) => updateField(val, "color")} />
          </div>
          <div className={classes.FormRow}>
            <FormLabel style={{ display: "block", marginBottom: "10px" }}>
              Leer lassen um Passwort nicht zu ändern
            </FormLabel>
            <TextField
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              name="nickname"
              value={password}
              label="Neues Passwort"
            />
          </div>
          <div className={classes.FormRow}>
            <TextField
              onChange={(e) => setPassword2(e.target.value)}
              type="password"
              name="nickname"
              value={password2}
              label="Neues Passwort wiederholen"
            />
          </div>

          <section className={classes.Buttons}>
            <Button className={classes.Button} variant="outlined" color="warning" onClick={logout}>
              Logout
            </Button>
            <Button className={classes.Button} type="submit" variant="contained">
              Speichern
            </Button>
          </section>
        </form>
      </FormGroup>
    </LocalizationProvider>
  );
}
