import classes from "./Event.module.css";

export default function Event(props) {
  const event = props.event?.attributes;
  const user = props.event?.attributes?.author?.data.attributes;

  const bulletClass = [classes.Bullet];
  if (!event.definite) {
    bulletClass.push(classes.Indefinite);
  }

  const author = props.showNicknames === 'true' ? user?.nickname : user?.username

  return (
    <div onClick={() => props.clickEvent(props.event?.id)} className={classes.Event}>
      <div
        style={{ backgroundColor: user?.color, borderColor: user?.color }}
        className={bulletClass.join(" ")}
      ></div>
      <span className={classes.Creator}>
        {author} ({event.visitors})
      </span>
    </div>
  );
}