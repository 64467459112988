import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import Controls from "./Controls/Controls";
import MonthView from "./MonthView/MonthView";
import classes from "./Calendar.module.css";
import Modal from "../Components/Modal/Modal";
import BookingForm from "../Components/BookingForm/BookingForm";
import ProfileForm from "../Components/ProfileForm/ProfileForm";
import SessionError from "../Components/SessionError/SessionError";

export default function Calendar(props) {
  const [currentMonth, setCurrentMonth] = useState();
  const [currentYear, setCurrentYear] = useState();
  const [swipeRight, setSwipeRight] = useState(false);
  const [swipeLeft, setSwipeLeft] = useState(false);
  const [events, setEvents] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [editing, setEditing] = useState(false);
  const [createOnDay, setCreateOnDay] = useState(false);
  const [editingProfile, setEditingProfile] = useState(false);

  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);

  const [loadingEventsError, setLoadingEventsError] = useState(false);

  const [loadingLong, setLoadingLong] = useState(false);
  const [loadingBookings, setLoadingBookings] = useState(false);
  const [showNicknames, setShowNicknames] = useState(false);

  const axiosOptions = {
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem("wallis-token")}`,
    },
  };

  const loadBookings = async () => {
    const loadTimer = setTimeout(()=>{
      setLoadingBookings(true);
    }, 1000);

    const longLoadTimer = setTimeout(() => {
      setLoadingLong(true);
    }, 5000);

    const date = moment(`${currentYear}-${currentMonth}-01`, "YYYY-MM-DD");
    const start = date.clone().subtract(2, "month").format("YYYY-MM-DD");
    const end = date.clone().add(2, "month").format("YYYY-MM-DD");

    axios.get(
      `${process.env.REACT_APP_API}/api/bookings?filters[$and][0][start_date][$gte]=${start}&filters[$and][1][end_date][$lte]=${end}&populate[0]=author&sort=start_date:DESC`,
      axiosOptions
    ).then(res => {
      setShowNicknames(window.localStorage.getItem("show-nicks"));
      setEvents(res.data.data);
      setLoadingEventsError(false);
    }).catch(err => {
      setLoadingEventsError(err);
      setModalOpen(true);
    }).finally(()=>{
      setLoadingBookings(false);
      setLoadingLong(false);
      clearTimeout(longLoadTimer);
      clearTimeout(loadTimer);
    })
  };

  const submitModalForm = () => {
    setModalOpen(false);
    loadBookings();
  };

  const clickEvent = (id) => {
    setCreateOnDay(false);
    setEditingProfile(false);
    setEditing(id);
    setModalOpen(true);
  };

  const clickDay = (day) => {
    setCreateOnDay(moment(day, "YYYY-MM-DD"));
    setEditingProfile(false);
    setEditing(false);
    setModalOpen(true);
  };

  const openProfileSettings = () => {
    setEditingProfile(true);
    setModalOpen(true);
  };

  const gotoToday = () => {
    const today = moment();
    const curr = moment(`${currentYear}-${currentMonth}-01`, "YYYY-MM-DD");

    setCurrentMonth(today.month() + 1);
    setCurrentYear(today.year());
    if (today.isAfter(curr, "month")) {
      setSwipeRight(true);
      setTimeout(() => setSwipeRight(false), 200);
    } else if (today.isBefore(curr, "month")) {
      setSwipeLeft(true);
      setTimeout(() => setSwipeLeft(false), 200);
    }
  };

  useEffect(() => {
    if (!currentMonth || !currentYear) {
      setCurrentMonth(moment().month() + 1);
      setCurrentYear(moment().year());
    }
  }, []);

  useEffect(() => {
    if (currentMonth && currentYear) {
      loadBookings();
    }
  }, [currentMonth, currentYear]);

  const nextMonth = () => {
    const date = moment(`${currentYear}-${currentMonth}-01`, "YYYY-MM-DD").add(
      1,
      "months"
    );
    setCurrentMonth(date.month() + 1);
    setCurrentYear(date.year());
    setSwipeRight(true);
    setTimeout(() => setSwipeRight(false), 200);
  };

  const prevMonth = () => {
    const date = moment(`${currentYear}-${currentMonth}-01`, "YYYY-MM-DD").add(
      -1,
      "months"
    );
    setCurrentMonth(date.month() + 1);
    setCurrentYear(date.year());
    setSwipeLeft(true);
    setTimeout(() => setSwipeLeft(false), 200);
  };

  const minSwipeDistance = 50;
  const onTouchStart = (e) => {
    setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX);

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isUpSwipe = distance > minSwipeDistance;
    const isDownSwipe = distance < -minSwipeDistance;
    if (isUpSwipe) {
      nextMonth();
    }
    if (isDownSwipe) {
      prevMonth();
    }
  };

  let modalContent = (
    <BookingForm
      showNicknames={showNicknames}
      submitBookingForm={submitModalForm}
      createOnDay={createOnDay}
      editing={editing}
    />
  );

  if(loadingEventsError){
    modalContent = <SessionError />
  } else if (editingProfile) {
    modalContent = <ProfileForm submitProfileForm={submitModalForm} />;
  }
  
  const weekdayLabels = ["Mon", "Die", "Mit", "Don", "Fre", "Sam", "Son"]

  return (
    <section className={classes.Calendar}>
      {(modalOpen) ? (
        <Modal content={modalContent} closeModal={() => setModalOpen(false)} />
      ) : null}
      <Controls
        currentDate={moment(`${currentYear}-${currentMonth}-01`, "YYYY-MM-DD")}
        prevMonth={prevMonth}
        nextMonth={nextMonth}
        gotoToday={gotoToday}
        openProfileSettings={openProfileSettings}
      />
      <section className={classes.Weekdays}>
        {weekdayLabels.map(w => <div key={w}>{w}</div>)}
      </section>
      <MonthView
        showNicknames={showNicknames}
        loading={loadingBookings}
        loadingLong={loadingLong}
        onTouchStart={(e) => onTouchStart(e)}
        onTouchMove={(e) => onTouchMove(e)}
        onTouchEnd={(e) => onTouchEnd()}
        setEditing={setEditing}
        clickEvent={(id) => clickEvent(id)}
        clickDay={(day) => clickDay(day)}
        events={events}
        swipeRight={swipeRight}
        swipeLeft={swipeLeft}
        currentMonth={currentMonth}
        currentYear={currentYear}
      />
    </section>
  );
}
