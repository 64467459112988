import classes from "./ColorPicker.module.css";

export default function ColorPicker(props) {
  return (
    <section className={classes.ColorPicker}>
      {props.colors.map((c, i) => (
        <div
          key={i}
          className={props.value === c ? classes.Active : null}
          onClick={() => props.onChange(c)}
          style={{ backgroundColor: c, color: c }}
        ></div>
      ))}
    </section>
  );
}
