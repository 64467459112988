import { useEffect, useState } from "react";
import classes from "./Modal.module.css";

export default function Modal(props) {
  const [active, setActive] = useState(false);

  const close = (e) => {
    if (
      e.target.classList.contains(classes.Modal) ||
      e.target.classList.contains(classes.Close)
    ) {
      setActive(false);
      setTimeout(() => {
        props.closeModal();
      }, 200);
    }
  };

  useEffect(() => {
    setActive(true);
  }, []);

  const cls = [classes.Inner];
  if (active) {
    cls.push(classes.Active);
  }

  return (
    <section onClick={(e) => close(e)} className={classes.Modal}>
      <article className={cls.join(" ")}>
        <div className={classes.Close}></div>
        {props.content}
      </article>
    </section>
  );
}
